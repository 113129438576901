.popoutMenu {
    margin: 0px;
    padding: 0px;
    border: 0px;
    border-bottom: 2px;
    border-style: solid;
    border-color: #B50;
    display:block;
    flex-direction: row;
    text-align: left;
}

.popoutMenuItem {
    display: inline-flex;
    margin: 0px;
    padding: 3px;
    border: 1px;
    border-style: solid;
    border-color: #b50;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.selectedText {
    font-weight: bold;
}

.selectedTab {
    background-color: #b50;
}