.toggle {
    position: absolute;
    top: 0px;
    left: 0px;
    color: #0ff;
    padding: 3px;
    border: 2px;
    border-color: #B50;
    border-style: solid;
    background-color: #B50;
    border-bottom-right-radius: 10px;
    font-style: italic;
}

.popout {
    position: absolute;
    top: 70px;
    overflow: auto;
    left: 10%;
    width: 80%;
    z-index: 100;
    min-height: 300px;
    height: 80%;
    background-color: #004;
    color: #0ff;
    border: 2px;
    border-top: 0px;
    border-color: #B50;
    border-style: groove;
    border-radius: 20px;
    font-size: 1.2em;
}

.hide {
    display: none
}

ul {
    text-align: left;
}

p {
    text-align: left;
    padding: 5px;
}

.wordLookup {
    text-align: left;
    width: 98%;
    min-width: 150px;
    margin: 5px
}

.lhs {
    display: inline-block;
    width: 150px;
    min-width: 150px;
    left: 0px;
    margin: 5px;

}

.inputBox {
    display: block;
    width: 150px;
    margin-bottom: 2px;
}

.wordLookupBox {
    display:block;
    width: 157px;
    background-color: #ccc;
    margin-bottom: 5px;
}

h3 {
    text-align: center;
}



.defnBox {
    display: inline-block;
    padding: 5px;
    text-align: left;
    margin-top: 5px;
    vertical-align: top;
    max-width: 50%;
    min-width: 30%;
    margin-left: 5%;
    margin-right: 5%;
    align-self: center;
}