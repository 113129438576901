.tutBox {
    position: absolute;
    top: 5px;
    left: 60px;
    background-color: #004;
    border: 2px;
    border-style: double;
    border-radius: 10px;
    border-color: #B50;
    padding: 15px;
    color: #0ff;
    max-width: 50%;
    max-height: 80%;
    overflow-y: auto;
    z-index: 200;
}

.NextButton {
    margin: 4px;
    padding: 6px;
    border-radius: 5px;
    background-image: radial-gradient(#0ff,#0dd, #eee);
    color: #000;
}