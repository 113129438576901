.historyTableChoice {
    display: inline-block;
    text-align: center;
    border: 2px;
    border-style: groove;
    border-color: #F50;
    border-radius: 10px;
    font-size: 0.7em;
}

.tdHeader {
    text-align: center;
    padding: 5px;
    padding-bottom: 0px;
    margin: 0px;
    font-weight: bold;
}

.tdSelect {
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;
}

.tdButton {
    text-align: center;
    border: 0px;
    border-top: 1px;
    border-style: solid;
    border-color: #F50;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.playedTd {
    text-align: center;
    border: 1px;
    border-style: solid;
    border-color: #F50;
    border-radius: 5px;
    padding: 4px;
}

.buttonLoad {
    width: 80%;
    font-size: 1.5em;
    font-weight: bold;
    padding: 8px;
    border-radius: 8px;
    background-image: radial-gradient(#0ff,#0dd, #eee);
}

.buttonLoadToday {
    width: fit-content;
    font-size: 0.7em;
    padding: 3px;
    border-radius: 3px;
    background-image: radial-gradient(#0ff,#0dd, #eee);
}

.tight {
    margin: 0px;
    border-spacing: 0px;
}

.puzzleCount {
    font-weight: bold;
    font-style: italic;
    font-size:  1.1em;
    margin: 30px;
    display: block;
}

.bold {
    font-weight: bold;
}