.gridCell {
    width: 32px;
    height: 32px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: black;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: x-large;
    text-align: center;
    align-items: center;
    margin: 0px;
    display: table;
    border-spacing: 0;
}
.blurred {
    filter: blur(1px);
}
@media screen  and (min-width: 600px) and (max-height: 375px){
    .gridCell {
        width: 27px;
        height: 27px;
        font-size: medium;
        font-weight: bold;
    }

}

@media screen  and (min-width: 505px) and (max-width: 685px) and (min-height: 890px){
    .gridCell {
        width: 48px;
        height: 48px;
    }
    .blurred {
        filter: blur(2px);
    }
}

@media screen  and (min-width: 685px) and (max-width: 900px) and (min-height: 890px){
    .gridCell {
        width: 60px;
        height: 60px;
    }
    .blurred {
        filter: blur(3px);
    }
}

@media screen and (min-width: 900px) and (min-height: 775px) {
    .gridCell {
        width: 60px;
        height: 60px;
        font-size: 2.5em;
        font-weight: bold;
    }
    .blurred {
        filter: blur(3px);
    }
}

@media screen and (min-width: 1200px) and (min-height: 900px) {
    .gridCell {
        width: 73px;
        height: 73px;
        font-size: 3.0em;
        font-weight: bold;
    }
    .blurred {
        filter: blur(4px);
    }
}

.standardFill {
    background-image: radial-gradient(#eee, #bbb, #eee);
}

@keyframes rotate {
    0% {transform: rotate(0)}
    100% {transform: rotate(9000deg);}
}

.rotateObject {
    animation: 10s ease rotate;
    animation-iteration-count: 1;
}

.locked {
    background-image: conic-gradient(#004, #B50,#004,  #B50,#004,  #B50,#004,  #B50, #004);
}

/*
@keyframes rotateLocked {
    0% {background-image: conic-gradient(from -90deg, #004, #B50,#004,  #B50,#004,  #B50,#004,  #B50, #004);}
    100% {background-image: conic-gradient(from 0deg, #004, #B50,#004,  #B50,#004,  #B50,#004,  #B50, #004);}
}
.rotateLocked {
    this just didn't work
    animation: rotateLocked;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
*/


.selected {
    background-image: radial-gradient( #AFF, #eee);
    /*font-weight: bold;*/
}

.suggested {
    background-image: radial-gradient(#0ff,#0dd, #eee);
    font-style: italic;
}

.conflict {
    /*background-image: conic-gradient(#811, #300,#811,  #300,#811,  #300,#811,  #300, #811);*/
    background-image: linear-gradient(0deg, #A00, #402, #004, #402, #A00);
    color: #ff0000;
    font-weight: bold;
}

.confirmed {
    /*background-color: aquamarine;*/
    background-image: radial-gradient(#FA6, #F50, #F95);
}

.lettersAvailable {
    font-size: small;
}

.valignTop {
    vertical-align: text-top;
    display: table-cell;
    padding-top: 5px;
}

.valignMiddle {
    vertical-align: middle;
    display: table-cell;
}

.hideText {
    display: none
}

.tableWidth {
    background-color: #B50;
}

.tableTight {
    padding: 0px;
    margin: 0px;
    border: 0px;
    border-spacing: 0px;
}
