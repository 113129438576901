.timePlayColor {
    color: yellow
}


.timePlayedLoc {
    position: fixed;
    top: 0px;
    right: 0px;
    padding-right: 5px;
    padding-top: 5px;
}
