.EntryActive {
    color: #000;
    background-image: radial-gradient(#0ff,#0dd, #eee);
}

.EntryDisabled {
    color: #0aa;
    background-image: radial-gradient(#8ff,#8dd, #eee);
}

.EntryButton {
    width: 40px;
    height: 40px;
    margin: 4px;
    border-radius: 5px;
    font-size: 1.5em;
}

.EntryButtonDiv {
    width: 320px;
    display: inline-block;
    text-align: center;
    align-self: center;
}

.ClearDisable {
    color: #c88;
    background-image: radial-gradient(pink 0%, pink 35%,black 40%, pink 45%, #f99 85%);
}

.ClearActive {
    color: #000;
    background-image: radial-gradient(pink 0%, pink 35%,black 40%, pink 45%, red 85%);
}

.ClearButton {
    width: 40px;
    height: 40px;
    margin: 4px;
    border-radius: 5px;
    font-size: 1.5em;
    font-weight: bold;
    background-image: radial-gradient(pink 0%, pink 35%,black 40%, pink 45%, red 85%);
}

@media screen and (min-width: 505px) and (max-width: 685px) and (min-height: 895px){
    .EntryButtonDiv {
        width: 500px;
        display: block
    }
    .EntryButton {
        width: 52px;
        height: 52px;
    }

    .ClearButton {
        width: 52px;
        height: 52px;
    }
}

@media screen  and (min-width: 685px) and (max-width: 900px) and (min-height: 895px){
    .EntryButtonDiv {
        width: 460px;
        display: inline-block
    }
    .EntryButton {
        width: 52px;
        height: 52px;
    }

    .ClearButton {
        width: 52px;
        height: 52px;
    }
}

@media screen and (min-width: 900px) and (min-height: 775px) {
    .EntryButtonDiv {
        width: 280px;
        display: inline-block
    }

    .EntryButton {
        width: 66px;
        height: 66px;
        margin: 10px;
        font-size: 2.0em;
    }

    .ClearButton {
        width: 66px;
        height: 66px;
        margin: 10px;
        font-size: 2.0em;
    }
}

@media screen and (min-width: 1200px) and (min-height: 900px) {
    .EntryButtonDiv {
        width: 380px;
        display: inline-block
    }

    .EntryButton {
        width: 100px;
        height: 100px;
        font-size: 3em;
    }

    .ClearButton {
        width: 100px;
        height: 100px;
        font-size: 3em;
    }
}