@keyframes bgColorChange {
    0% {
        background-color: #fff;
    }
    50% {
        background-color: #0ff;
    }
    100% {
        background-color: #fff;
    }
}

.hintButton {
    display: block;
    width: 150px;
    padding: 5px;
    border: 2px;
    border-color: black;
    border-style: groove;
    border-radius: 5px;
    animation-name: bgColorChange;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

@media screen  and (min-width: 505px) and (max-width: 685px) and (min-height: 890px){
    .hintButton {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen  and (min-width: 685px) and (max-width: 900px) and (min-height: 895px){
    .hintButton {
        display: block;
    }
}