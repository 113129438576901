.gridDivCenter {
    justify-content: center;
    margin-top: 5px;
    display: inline-block;
}

.grid {
    display: inline-block;
    width: 340px;
}

/* When the width is between 600px and 900px
OR above 1100px - change the appearance of <div>

@media screen
    and (max-width: 900px)
    and (min-width: 600px),
    (min-width: 1100px)

user input max height: 300px at base level
Min ad height: 80px (include 20px buffer)
title: 70px

don't froget 25 px for scrollbars
*/

@media screen  and (max-width: 375px) and (min-height: 650px){
    .grid {
        width: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen  and (min-width: 600px) and (max-height: 375px){
    .grid {
        width: 300px;
        display: inline-block;
    }
}

@media screen  and (min-width: 505px) and (max-width: 685px) and (min-height: 890px){
    .grid {
        width: 500px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}


@media screen  and (min-width: 685px) and (max-width: 900px) and (min-height: 775px) {
    .grid {
        width: 660px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width: 900px) and (min-height: 775px) {
    .grid {
        width: 660px;
        display: inline-block;
    }
}

@media screen and (min-width: 1200px) and (min-height: 900px) {
    .grid {
        width: 800px;
        display: inline-block;
    }
}