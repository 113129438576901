/*
The first full layout fits in 345x780
*/

@keyframes backgroundRotate {
 0% {
    background-image: radial-gradient(#000 20%,#004 60%,#002 80%)
    /*background-color: #004;*/
 }

 50% {
    background-image: radial-gradient(#000 20%,#008 60%,#002 80%)
    /*background-color: darkgoldenrod;*/
 }

 100% {
    background-image: radial-gradient(#000 20%,#004 60%,#002 80%)
    /*background-color: #004;*/
 }
}

.everything {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 0px;
    min-height: fit-content;
    height: fit-content;
    text-align: center;
    background-image: linear-gradient(#000, #004 30%, #002 90%);
    /*background-image: radial-gradient(#000 20%,#004 60%,#002 80%);*/
    vertical-align: text-top;
    /*animation-name: backgroundRotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;*/
}

.container {

    top: 0px;
    left: 0px;
    width: max-content;
    padding: 2px;
    height: fit-content;
    text-align: center;
    vertical-align: text-top;
    /*animation-name: backgroundRotate;
    animation-duration: 10s;
    animation-iteration-count: infinite;*/
}

.adContainer {
    display: block;
    text-align: center;
    width: 100%;
}

.adContainerDisplay {
    background-color: #004;
}

.userInputAdContainer {
    display: block;
}

@media screen  and (min-width: 600px) and (max-height: 650px){
    .userInputAdContainer {
        display: inline-block;
        vertical-align: top;
    }
}

@media screen and (min-width: 900px) and (min-height: 775px) {
    .userInputAdContainer {
        display: inline-block;
        vertical-align: top;
    }
}

@media screen  and (min-width: 505px) and (max-width: 900px) and (min-height: 895px){
    .userInputAdContainer {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen  and (min-width: 600px) and (max-height: 375px){
    .userInputAdContainer {
        display: inline-block;
        vertical-align: top;
    }
}

.ad {
    width: 320px;
    height: 50px;
    margin-top: 0px;
    margin-bottom: 5px;
    background-color: #500;
    text-align: center;
    color: white;
    display: inline-block;
}


.title {
    font-size: 2.0em;
    font-style: italic;
    font-weight: bold;
    color: #0ff;
    text-align: center;
    width: fit-content;
    height: fit-content;
    padding: 15px;
    border: 3px;
    border-style: solid;
    border-color: #006;

    border-radius: 10px;
    background-image: linear-gradient(15deg, #004, #B50, #004);
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 5px;
    line-height: 1;
}