.helpHint {
    font-size: 0.4em;
    line-height: 1.0em;
    vertical-align: top;
    text-align: center;
    margin-block-start: 0.3em;
    margin-block-end: 0em;
    white-space: pre-line;
}

.wordTypedAlign {
    text-align: center;
    vertical-align: middle;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.userInput {
    display: block;

    align-self: center;
    align-items: center;
}

.wordTyped {
    font-size: 2.0em;
    width: 200px;
    height: 60px;
    border: 2px;
    border-style: solid;
    border-color: black;
    border-radius: 10px;
    background-image: linear-gradient(#0ff,#eee 30%,#eee 70%,#0dd);
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wordTypedHintGroup {
    display: block
}


@media screen  and (min-width: 505px) and (max-width: 900px) and (min-height: 895px){
    .userInput {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wordTypedHintGroup {
        display: inline-block;
        text-align: center;
        align-self: center;
        vertical-align: top;
        margin-top: 8px;
    }
}

@media screen and (min-width: 900px) and (min-height: 775px) {
    .userInput {
        display: inline-block;
        vertical-align: top;
    }

    .wordTypedHintGroup {
        display: block;
        text-align: center;
        align-self: center;
        margin-top: 8px;
    }
}

@media screen  and (min-width: 600px) and (max-height: 375px){
    .userInput {
        display: inline-block;
        vertical-align: top;
    }
    .wordTyped {
        font-size: 1.4em;
        width: 200px;
        height: 40px;
    }
}

@media screen  and (max-width: 375px) and (min-height: 650px){
    .userInput {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .wordTyped {
        font-size: 1.4em;
        width: 200px;
        height: 40px;
    }
}