@keyframes revealTimerScore {
    0% {color: #004}
    20% {color: #0ff}
    25% {color: #004}
    98% {color: #004}
    100% {color: #0ff}
}

@keyframes revealHintScore {
    0% {color: #004}
    20% {color: #004}
    40% {color: #0ff}
    45% {color: #004}
    98% {color: #004}
    100% {color: #0ff}
}

@keyframes revealWordScore {
    0% {color: #004}
    40% {color: #004}
    60% {color: #0ff}
    65% {color: #004}
    98% {color: #004}
    100% {color: #0ff}
}

@keyframes revealTotalScore {
    0% {color: #004}
    90% {color: #004}
    100% {color: #0ff}
}




.timerScore {
    animation-name: revealTimerScore;
    animation-duration: 10s;
    animation-iteration-count: 1;
}

.hintScore {
    animation-name: revealHintScore;
    animation-duration: 10s;
    animation-iteration-count: 1;
}

.wordScore {
    animation-name: revealWordScore;
    animation-duration: 10s;
    animation-iteration-count: 1;
}

.totalScore {
    animation-name: revealTotalScore;
    animation-duration: 10s;
    animation-iteration-count: 1;
}

.youWin {
    color: #0ff;
    width: 40%;
    min-width: min-content;
    align-items: center;
    align-self: center;
    border: 2px;
    border-style: groove;
    border-color: #004;
    border-radius: 10px;
    background-color: #004;
    display: inline-block;
    vertical-align: top;

}

@keyframes circleSwipe {
    /* X/Y  LEFT=100, RIGHT=0, TOP=100, BOTTOM=0
    top left 100/100
    top right 0/100
    bottom right  0/0
    bottom left 100/0

    timer 20% Y=63%
    hint 40%  Y=44%
    word: 60% Y=26%
    total: >90% Y=8%
    */
    0% {background-position: 150% 58%;}
    10% {background-position: -50% 68%;} /* TIMER MOVE */
    20% {background-position: -50% 39%;}
    30% {background-position: 150% 49%;} /* HINT MOVE */
    40% {background-position: 150% 31%;}
    50% {background-position: -50% 21%;} /* WORD MOVE */
    51% {background-position: -50% -40%;}
    52% {background-position: 70% -40%;}
    65% {background-position: 70% -40%;}
    74% {background-position: 40% 120%;}
    /*70% {background-position: -50% 120%;} /* REposition up */
    75% {background-position: 9% 120%;} /* Reposition right */
    90% {background-position: 9% 10%;}    /* Move Down, flash total */
    100% {background-position: 9% -20%;}

}

.animateTable {
    animation-name: circleSwipe;
    animation-duration: 10s;
    animation-iteration-count: 1;
    background-image: radial-gradient(#0ff 0%, #004 7%, #004 90% );
    background-size: 200% 200%;
    background-position: 10% -20%;
}
.scoreTable {
    text-align: center;
    width: 100%;
    margin: 5px;
    border: 2px;
    border-color: #000;
    border-style: solid;
    font-size: 1.0em;
    /*
    background-position: -50% -225%;
    */
}

.winTableClipboard {
    background-color: #004;
    padding: 0px;
    margin: 0px;
}

.winStyle {
    font-size: 1.5em;
    font-weight: bold;
    font-style: italic;
}

select {
    width: fit-content;
    min-width: 40%;
    border-radius: 5px;
    padding: 2px;
    line-height: normal;
    text-align: center;
}

.myOption {
    padding: 0px;
    margin: 0px;
    line-height: 1.0;
    min-height: fit-content;
    width: fit-content;
    text-align: left;
}

@keyframes nextPuzzleFadeIn {
    0% {opacity: 0;}
    80% {opacity: 0;}
    100% {opacity: 1}
}

.nextPuzzle {
    font-size: 0.8em;
    font-style: italic;
    color: #0ff;
    animation-name: nextPuzzleFadeIn;
    animation-duration: 15s;
    animation-iteration-count: 1;
    text-align: center;
    display: block;
    margin: 1px;
}

.hideSolutionAnimation {
    animation-name: nextPuzzleFadeIn;
    animation-duration: 15s;
    animation-iteration-count: 1;
}

.hideSolutionButton {
    border-radius: 25px;
    padding: 10px;
    font-weight: bold;
    font-style: italic;
}

.sidePadding {
    padding-left: 5px;
    padding-right: 8px;
}